import React from "react";

import Heroimg from "../../assets/images/Heroimg.svg";
import Awslogo from "../../assets/images/awslogo.svg";

export default function Hero() {
  return (
    <div className=" w-11/12 pt-32 2xl:max-w-[1240px] mx-auto ">
      <div className="grid grid-cols-12">
        <div className="col-span-12 xl:col-span-7 2xl:col-span-8 ">
          <div className="pt-[30px] sm:pt-[72px] md:pt-[135px] pb-[50px] xl:pb-[204px] ">
            <h4 className=" text-5xl sm:text-8xl  2xl:text-[64px] f-f-b-g text-white  sm:leading-[72px] ">
              Eliminate USD Transactions, Unlock Discounts &{" "}
              <span className="text-green ">
                {" "}
                Pay Later{" "}
                <span className=" relative ">
                  {" "}
                  on{" "}
                  <img
                    src={Awslogo}
                    alt=""
                    style={{
                      width: 107,
                      height: 64,
                      display: "inline",
                      marginLeft: 12,
                      marginBottom: -28,
                    }}
                  />
                </span>
              </span>
            </h4>
            <h5 className=" f-f-m text-lg 2xl:text-xl  text-grey mt-[24px] ">
              with SaaSPay’s Customer First Payments solution
            </h5>
            <ul className=" mt-[48px]  md:inline-flex items-center ">
              <li>
                <button
                  className=" bg-white w-full md:w-[161px] h-[64px] f-f-b text-lg text-primary-vdark rounded-[48px]"
                  onclick={() =>
                    window.open(
                      "https://calendly.com/mukund-saas/get-to-know-saaspay?month=2024-04"
                    )
                  }
                >
                  Learn More
                </button>
              </li>
              <li className=" ml-[24px] mt-[24px] md:mt-0 ">
                {/* inner ul started */}
                <ul
                  className="inline-flex items-center cursor-pointer"
                  onclick={() =>
                    window.open(
                      "https://calendly.com/mukund-saas/get-to-know-saaspay?month=2024-04"
                    )
                  }
                >
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.2031 9.39844L20.6031 14.9984L12.2031 20.5984V9.39844Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </li>
                  <li className=" ml-[12px] ">
                    <h2 className=" f-f-b text-lg text-white ">
                      Book a meeting to start saving 40% on your AWS bill
                    </h2>
                  </li>
                </ul>
                {/* inner ul edned */}
              </li>
            </ul>
          </div>
        </div>
        <div className="  col-span-12 xl:col-span-5 2xl:col-span-4 ">
          <img
            src={Heroimg}
            className=" w-full 2xl:w-[556px] h-[629px] sm:mb-[42px] sm:mt-[42px] lg:mt-0 2xl:absolute 2xl:right-0 "
          />
        </div>
      </div>
    </div>
  );
}
