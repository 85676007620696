import React from "react";
import Buyingpage from "../components/BuyingOnAws/index";
import { PageSEO } from "../atoms/SEO";

export default function Buying({ location }) {
  return (
    <div>
      <PageSEO
        path={location.pathname}
        title={"SaaSPay's B2B Buy-Now-Pay-Later for AWS marketplace"}
        description={
          "Eliminate USD transactions for your B2B SaaS & Cloud payments and unlock discounts on AWS marketplace with SaaSPay's Buy-Now-Pay-Later solution."
        }
      />
      <Buyingpage />
    </div>
  );
}
