import React from "react";

import Rectangle from "../../assets/images/insight1.svg";
import doctoer from "../../assets/images/insight2.svg";
import laptp from "../../assets/images/insight3.svg";

export default function Insights() {
  return (
    <div className="pt-[76px]">
      <div className=" w-11/12 2xl:max-w-[1170px] 3xl:max-w-[1500px] mx-auto ">
        <div className="grid grid-cols-12 gap-4 items-center ">
          <div className=" col-span-12 lg:col-span-10 text-center lg:text-left">
            <h2 className=" text-black f-f-b-g text-4xl sm:text-7xl ">
              Discover AWS Discounts & Best Practices
            </h2>
          </div>
          <div className=" col-span-12  lg:col-span-2 text-center lg:text-right ">
            <button
              className="  bg-primary w-[172px] h-[64px] rounded-[48px] f-f-b text-lg text-white"
              onclick={() => window.open("https://blog.saaspay.com/")}
            >
              Read Blogs
            </button>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 2xl:gap-[30px] mt-[60px] ">
          <div className=" col-span-12 md:col-span-6 xl:col-span-4  text-center md:text-left ">
            <a href="https://blog.saaspay.com/4-ways-you-re-doing-cross-border-payments-all-wrong-for-aws-marketplace-purchases">
              <img src={Rectangle} className=" w-full " alt="" />
              <h5 className=" text-black text-xl f-f-b-g mt-2 xl:leading-[34px] mt-[30px] ">
                4 ways you’re doing cross-border payments all wrong
              </h5>
            </a>
          </div>
          <div className=" col-span-12 md:col-span-6 xl:col-span-4  text-center md:text-left ">
            <a href="https://blog.saaspay.com/best-aws-cloud-cost-optimization-tips-for-finops">
              <img src={doctoer} className=" w-full   " alt="" />
              <h5 className=" text-black text-xl f-f-b-g mt-2 xl:leading-[34px] mt-[30px] ">
                Best AWS Cloud Cost Optimization Tips for FinOps
              </h5>
            </a>
          </div>
          <div className=" col-span-12 md:col-span-6 xl:col-span-4  text-center md:text-left ">
            <a href="https://blog.saaspay.com/cloud-cost-optimization-8-thinking-principles-for-saas-cfos">
              <img src={laptp} className=" w-full  " alt="" />
              <h5 className=" text-black text-xl f-f-b-g mt-2 xl:leading-[34px] mt-[30px] ">
                Cloud Cost Optimization: 8 Thinking Principles for SaaS CFOs
              </h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
