import React from "react";

import Navbar from "../../organisms/Layout/Navbar";
import Hero from "./Hero";
import Introducing from "./Introducing";
import Seller from "./Seller";
import Faq from "../../molecules/Faq";
import Changing from "./Changing";
import Footer from "../../organisms/Layout/Footer";
import Insights from "./insights";

export const FaqData = [
  {
    heading: "What is SaaSPay?",
    content:
      "SaaSPay is an AWS exclusive CPPO payments partner working to solve for multi-currency transactions & payment flexibility.",
  },
  {
    heading: "What is the AWS marketplace?",
    content:
      "AWS Marketplace is a digital catalog of software solutions, services, and products that are pre-configured for use on the Amazon Web Services (AWS) cloud platform. It offers a wide range of offerings from independent software vendors (ISVs) and service providers, making it easier for businesses to find, purchase, and deploy software and services directly within their AWS environment.",
  },
  {
    heading: "How does pay later work on AWS?",
    content:
      "Pay Later on AWS allows you to use native services and pay back on your own terms! This option provides flexibility in managing your cash flow, enabling you to use AWS services immediately while deferring payment for a more convenient time. It simplifies billing and can be a valuable resource for businesses looking to optimize their financial operations.",
  },
  {
    heading: "How does net 90 payment work?",
    content:
      "Net 90 payment terms mean that you have 90 days from the date of purchase to make the payment for the AWS services you've used. This extended payment period helps you improve cash flow management by delaying the settlement of charges. It offers financial flexibility and allows you to invest in your business without immediate payment constraints.",
  },
  {
    heading: "What are the advantages of AWS marketplace billing?",
    content:
      "With the Flexible Payment Options, you can now choose from various payment methods, including Pay Later and Net 90, to match your financial needs. There is not any rigidity involved anymore, so you grow on your terms while enjoying the benefits of your favorite SaaS solutions.",
  },
  {
    heading: "What are the 90-day invoice terms?",
    content:
      "The 90-day invoice terms refer to the payment terms available in the AWS Marketplace, specifically in the Net 90 payment option. With this, you have 90 days to settle the invoice for AWS services you've used. This extended period provides greater flexibility for managing your budget and cash flow while still benefiting from AWS's cloud services.",
  },
];

export default function index() {
  return (
    <div>
      <Navbar dark />
      <div className=" bg-primary-vdark  ">
        <Hero />
      </div>
      <Seller />
      <Changing />
      <Introducing />
      <Insights />
      <div className=" mt-[110px] ">
        <Faq data={FaqData} />
      </div>
      <Footer />
    </div>
  );
}
